import {TaskEither} from 'fp-ts/TaskEither'
import {TE} from '../fp-ts-imp'
import {flow, pipe} from 'fp-ts/lib/function'

export const tap =
  <A>(f: (a: A | string) => void) =>
  (m?: string) =>
  (a: A): A => {
    if (m) f(m)
    f(a)
    return a
  }

const consoleLogTyped = <A>(a: A) => console.log(a)
const consoleLogTypedE = <A>(a: A) => console.error(getErrorString(a))
export const tapConsole = tap(consoleLogTyped)
export const tapConsoleE = tap(consoleLogTypedE)

export const getErrorString = (err: Error | unknown) =>
  err instanceof Error ? err.message : JSON.stringify(err)

export const tapConsoleLTE =
  <E, A>(msg?: string) =>
  (te: TaskEither<E, A>): TaskEither<E, A> =>
    pipe(te, TE.mapLeft(tapConsoleE(`${msg} :ERROR:`)))

export const tapConsoleRTE =
  <E, A>(msg?: string) =>
  (te: TaskEither<E, A>): TaskEither<E, A> =>
    pipe(te, TE.map(tapConsole(`${msg} : Success`)))

export const tapConsoleTE =
  <E, A>(msg?: string) =>
  (te: TaskEither<E, A>): TaskEither<E, A> =>
    pipe(
      te,
      TE.map(tapConsole(`${msg} : SUCCESS`)),
      TE.mapLeft(tapConsoleE(`${msg} :ERROR:`)),
    )

export const log =
  <A>(f: (a: A | string) => void) =>
  (m: string) =>
  (a: A): A => {
    f(m)
    return a
  }

export const logConsole = log(consoleLogTyped)
