import {pipe} from 'fp-ts/lib/function'
import {useCallback, useState} from 'react'
import {
  Datagrid,
  EmailField,
  List,
  TextField,
  EditButton,
  Edit,
  SimpleForm,
  TextInput,
  SaveButton,
  Toolbar,
  Button,
  useEditContext,
  ToolbarProps,
  ArrayField,
  Create,
  DateInput,
} from 'react-admin'
import {TE} from 'shared/dist/fp-ts-imp'
import PosIntInput from './comps/PosIntInput'
import {addUserToAccessTravel, AdminUser} from './dataProvider'
import {FormControlLabel, FormGroup, Switch} from '@mui/material'

const userFilters = [
  <TextInput label="Phone Number" source="phoneNbr" alwaysOn />,
  <TextInput label="Email" source="email" alwaysOn />,
  <TextInput label="Name" source="name" />,
]
export const UserList = () => (
  <List filters={userFilters}>
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <TextField source="name" label="Name" />
      <EmailField source="email" />
      <TextField source="phoneNbr" label="Phone" />
      <EditButton />
    </Datagrid>
  </List>
)
const UserEditToolbar: React.FC<ToolbarProps> = props => {
  const {record: user} = useEditContext<AdminUser>()
  const [isCAD, setIsCAD] = useState(false)

  const onAddAccessTravelUser = useCallback(() => {
    if (!user) return
    pipe(
      addUserToAccessTravel(user.id, isCAD),
      TE.map(({cvt}) =>
        alert(`User added to Access Travel program with CVT (${cvt})`),
      ),
    )()
  }, [user, isCAD])

  return (
    <Toolbar
      style={{
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: 10,
      }}
      {...props}
      sx={{columnGap: 10}}>
      <FormGroup style={{flexDirection: 'row', gap: 15}}>
        <Button
          label="Add Access Travel User"
          onClick={onAddAccessTravelUser}
        />

        <FormControlLabel
          control={
            <Switch value={isCAD} onChange={() => setIsCAD(cv => !cv)} />
          }
          label="Use Canadian Currency?"
        />
      </FormGroup>
      <SaveButton />
    </Toolbar>
  )
}

const CommonFields = () => (
  <>
    <TextInput source="name" />
    <TextInput source="email" />
    <PosIntInput fieldName="phoneNbr" label="Phone" />
    <TextInput source="fundraiserName" label="Fundraiser Name" />
    <TextInput
      source="participantImageUrl"
      label="Fundraiser Logo URL"
      fullWidth
    />
    <TextInput source="fundraiserId" label="Fundraiser Id" />
    <DateInput source="subscriptionEndDate" label="Subscription End Date" />
  </>
)

export const UserEdit = () => {
  return (
    <Edit>
      <SimpleForm toolbar={<UserEditToolbar />}>
        <CommonFields />
        <br />
        Push notification tokens:
        <ArrayField source="pushTokens" label="Push Notification Tokens">
          <Datagrid bulkActionButtons={false}>
            <TextField source="token" />
            <TextField source="platform" />
          </Datagrid>
        </ArrayField>
      </SimpleForm>
    </Edit>
  )
}

export const UserCreate = () => {
  return (
    <Create>
      <SimpleForm>
        <CommonFields />
        <TextInput source="address1" defaultValue="" hidden />
        <TextInput source="address2" defaultValue="" hidden />
        <TextInput source="city" defaultValue="" hidden />
        <TextInput source="state" defaultValue="" hidden />
        <TextInput source="zip" defaultValue="" hidden />
        <TextInput source="stripeToken" defaultValue="" hidden />
        <TextInput source="accessCVT" defaultValue="" hidden />
        <TextInput source="subscriptionStarted" defaultValue={true} hidden />
      </SimpleForm>
    </Create>
  )
}
